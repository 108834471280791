<script>
import { getImgSourceFromName } from "@/views/lib/utils/getImgSourceFromName";

export default {
  name: "ImpactRoundedImg",
  props: {
    categoryName: {
      type: String,
      default: "Health & Medicine",
    },
  },
  setup(props) {
    const imgSrc = getImgSourceFromName(props.categoryName);

    return { imgSrc };
  },
};
</script>
<template>
  <img
    :src="imgSrc"
    alt="Health & Medicine"
    class="rounded-full w-72 aspect-square object-cover"
  />
</template>

<style scoped></style>
