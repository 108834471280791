<template>
  <LSection>
    <template #body>
      <header class="heading1 !mb-2">
        <slot name="title"></slot>
        <hr class="gradientLine" />
      </header>
      <div class="flex gap-4 my-8 items-center">
        <slot name="description"></slot>
        <slot name="image"></slot>
      </div>
      Replace this with all projects from the specific area
      <CardsNews></CardsNews>
    </template>
  </LSection>
</template>

<script>
import CardsNews from "@/components/News/CardsNews.vue";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "LImpact",
  components: { LSection, CardsNews },
};
</script>

<style scoped></style>
